<template>
    <div>
        <BlockContainer
            :background-color="data.backgroundColor"
            :bottom-angle="data.bottomAngle"
            :angle-background-color="
                data.nextBackgroundColor ? data.nextBackgroundColor : null
            "
            :arrow-callout="data.arrowCallout"
            :id="'sample-pack-selector-' + slugify(data.heading)"
        >
            <template #container>
                <div class="container">
                    <div class="grid pt-20 md:grid-cols-1">
                        <div class="flex flex-col justify-end">
                            <div>
                                <h2>
                                    {{ data.heading }}
                                </h2>
                                <SamplePack
                                    v-for="(pack, key) in data.samplePacks"
                                    :key="key"
                                    :data="pack"
                                    @toggle="toggleProduct(pack.product)"
                                />
                                <div class="flex flex-col py-14">
                                    <p class="mb-12 text-right text-sm">
                                        *Note: Each sample pack will ship
                                        individually and will have separate
                                        shipping charges.
                                    </p>
                                    <div class="flex justify-end">
                                        <LoadingButton
                                            class="btn-primary w-full md:w-auto"
                                            :loading="addingToCart"
                                            @click="addToCart"
                                        >
                                            Add to Cart
                                        </LoadingButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BlockContainer>
        <AddToCartFlyout ref="addToCartFlyout" />
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SamplePackSelectorType from '~/types/SamplePackSelectorType';
import SamplePackType from '~/types/SamplePackType';
import BlockContainer from '~/components/BlockContainer.vue';
import SamplePack from './components/SamplePack.vue';
import AddToCartFlyout from '~/components/cart/AddToCartFlyout.vue';
import { Buyable, createBuyable } from '~/types/Buyable';
import { Image } from '~/types/miscellaneous';

const props = defineProps<{
    data: SamplePackSelectorType;
}>();

const addToCartFlyout = ref<InstanceType<typeof AddToCartFlyout> | null>(null);
const addingToCart = ref(false);
const selectedProducts: Ref<SamplePackType> = ref([]);

const toggleProduct = (product) => {
    const index = selectedProducts.value.indexOf(product);
    if (index === -1) {
        selectedProducts.value.push(product);
    } else {
        selectedProducts.value.splice(index, 1);
    }
};

const addToCart = async () => {
    if (!selectedProducts.value.length) {
        return;
    }

    addingToCart.value = true;

    const products = selectedProducts.value.map(
        (item: SamplePackType): Buyable =>
            createBuyable({
                id: item.id,
                image: item.image as Image,
                name: item.name,
            }),
    );

    await useAddToCart(products, addToCartFlyout.value).finally(() => {
        addingToCart.value = false;
    });
};
</script>
